import React, { useEffect } from "react";
import { Typography, Box, TextField, Button } from "@mui/material";
import RiasecPoster from "../../assets/RiasecPoster.png";
import LsmtPoster from "../../assets/LsmtPoster.png";
import wavyLineShort from "../../assets/Wavy line Short.png";
import wavyLineLong from "../../assets/Wavy line long.png";
import curlyArrow from "../../assets/Arrow@2x.png";
import glow from "../../assets/glow.png";

function RiasecAndLsmtPoster() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "space-around",
          height: "100%",
          backgroundColor: "#fff",
          // border: "1px solid black",
          marginTop: "1rem",
        }}
      >
        <div
          className="Riasec&LsmtBanner"
          style={{
            backgroundColor: "#2356FE",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
            padding: "0.7rem 2rem",
            margin: "1rem 0rem",
          }}
        >
          <h3
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize:
                window.innerWidth >= 1600
                  ? "40px"
                  : window.innerWidth >= 1200
                  ? "32px"
                  : window.innerWidth >= 768
                  ? "24px"
                  : window.innerWidth >= 480
                  ? "18px"
                  : "14px",
              textAlign: "center",
            }}
            className="Riasec&Lsmt-header"
          >
            RIASEC & LSMT
          </h3>
        </div>
        <div
          className="posters"
          style={{
            // border: "2px solid brown",
            display: "flex",
            flexDirection: window.innerWidth >= 1020 ? "row" : "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="RiasecPoster"
            style={{
              // border: "2px solid green",
              // height: "435px",
              // width: "325px",
              height:
                window.innerWidth >= 768
                  ? "400px"
                  : window.innerWidth >= 420
                  ? "400px"
                  : "350px",
              width:
                window.innerWidth >= 768
                  ? "325px"
                  : window.innerWidth >= 420
                  ? "325px"
                  : "270px",
              position: "relative",
              marginBottom: window.innerWidth <= 768 ? "10px" : "0rem",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              src={RiasecPoster}
              alt="Risec_poster"
              width="100%"
              height="100%"
            />
            <div
              className="bottom-left"
              style={{
                position: "absolute",
                bottom: "75px",
                left: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  border: "2px solid #2356FE",
                  backgroundColor: "white",
                  color: "#2356FE",
                  paddingLeft: "0.2rem",
                  borderRadius: "100%",
                }}
              >
                01
              </div>
              <div style={{ color: "#2356FE", padding: "0.3rem" }}>
                Give Test
              </div>
            </div>
            <Typography
              className="top-left"
              sx={{
                position: "absolute",
                top: "30px",
                left: window.innerWidth > 385 ? "6px" : "-4px",
                // left: "6px",
                textAlign: "center",
                fontSize: window.innerWidth > 385 ? "40px" : "30px",
                fontWeight: "bold",
                width: "18rem",
                color: "white",
                fontFamily: "Luckiest Guy",
              }}
            >
              MAKE <br /> YOUR CAREER WITH{" "}
              <Typography
                variant="span"
                sx={{
                  color: "#2356FE",
                  fontSize: "42px",
                  fontFamily: "Luckiest Guy",
                }}
              >
                RIASEC!
              </Typography>
            </Typography>
            <div
              className="top-right"
              style={{
                position: "absolute",
                bottom: "8px",
                left: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  border: "2px solid #2356FE",
                  backgroundColor: "white",
                  color: "#2356FE",
                  paddingLeft: "0.2rem",
                  borderRadius: "100%",
                }}
              >
                03
              </div>
              <div style={{ color: "#2356FE", padding: "0.3rem" }}>
                Choose Career
              </div>
            </div>
            <div
              className="bottom-right"
              style={{
                position: "absolute",
                bottom: "40px",
                left: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  border: "2px solid #2356FE",
                  backgroundColor: "white",
                  color: "#2356FE",
                  paddingLeft: "0.2rem",
                  borderRadius: "100%",
                }}
              >
                02
              </div>
              <div style={{ color: "#2356FE", padding: "0.3rem" }}>
                Analyze Result
              </div>
            </div>
            <div
              className="top-right"
              style={{
                position: "absolute",
                bottom: "110px",
                left: "135px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {" "}
              <img
                src={curlyArrow}
                alt="Curly_arrow_left"
                width="70%"
                height="70%"
              />
            </div>
            <div
              className="top-right"
              style={{
                position: "absolute",
                bottom: "150px",
                left: "-10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {" "}
              <img
                src={wavyLineShort}
                alt="Curly_arrow_left"
                width="70%"
                height="70%"
              />
            </div>
            <div
              className="top-right"
              style={{
                position: "absolute",
                top: "-30px",
                right: "-10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {" "}
              <img
                src={wavyLineLong}
                alt="Curly_arrow_left"
                width="80%"
                height="80%"
              />
            </div>
            <div
              className="centered"
              style={{
                position: "absolute",
                // top: "66%",
                // left: "32%",
                top:
                  window >= 1020
                    ? "66%"
                    : window.innerWidth >= 768
                    ? "63%"
                    : window.innerWidth >= 420
                    ? "63%"
                    : "60%",

                left:
                  window >= 1020
                    ? "32%"
                    : window.innerWidth >= 768
                    ? "32%"
                    : window.innerWidth >= 420
                    ? "32%"
                    : "32%",

                transform: "translate(-50%, -50%)",
                fontSize: "18px",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#2356FE",
                    position: "relative",
                    left: "-7px",
                    top: "-5px",
                    // left:
                    //   window >= 1020
                    //     ? "-7px"
                    //     : window.innerWidth >= 768
                    //     ? "-7px"
                    //     : window.innerWidth >= 480
                    //     ? "375px"
                    //     : "350px",
                    // top:
                    //   window >= 1020
                    //     ? "-5px"
                    //     : window.innerWidth >= 768
                    //     ? "-4px"
                    //     : window.innerWidth >= 480
                    //     ? "375px"
                    //     : "350px",

                    padding: "0.5rem",
                    fontFamily: "Jaro",
                    fontSize: window.innerWidth >= 420 ? "1.2rem" : "1rem",
                    color: "white",
                  }}
                >
                  How to give test?
                </Typography>
              </div>
            </div>
          </div>

          <div
            className="LsmtPoster"
            style={{
              // border: "2px solid green",
              height:
                window.innerWidth >= 768
                  ? "400px"
                  : window.innerWidth >= 420
                  ? "400px"
                  : "350px",
              width:
                window.innerWidth >= 768
                  ? "325px"
                  : window.innerWidth >= 420
                  ? "325px"
                  : "270px",
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              src={LsmtPoster}
              alt="Lsmt_poster"
              width="100%"
              height="100%"
            />
            <div
              className="bottom-left"
              style={{
                position: "absolute",
                bottom: "190px",
                left: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "#2C5DFE",
              }}
            >
              <div>01.</div>
              <div style={{ padding: "0.3rem" }}>Give Test</div>
            </div>
            <Typography
              className="top-left"
              sx={{
                position: "absolute",
                top: "30px",
                left: window.innerWidth > 480 ? "6px" : "-7px",
                textAlign: "center",
                fontSize: window.innerWidth > 480 ? "30px" : "25px",
                fontWeight: "bold",
                width: "18rem",
                color: "#91AFE3",
                fontFamily: "Margarine",
              }}
            >
              Let’s explore and grow with LSMT
            </Typography>
            <div
              className="top-right"
              style={{
                position: "absolute",
                // top: "110px",
                // left: "190px",
                top:
                  window >= 1020
                    ? "110px"
                    : window.innerWidth >= 768
                    ? "90px"
                    : window.innerWidth >= 420
                    ? "100px"
                    : "70px",

                left:
                  window >= 1020
                    ? "190px"
                    : window.innerWidth >= 768
                    ? "190px"
                    : window.innerWidth >= 420
                    ? "130px"
                    : "130px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {" "}
              <img
                src={glow}
                alt="glowing_rays"
                width={window.innerWidth > 480 ? "70%" : "50%"}
                height="70%"
              />
            </div>
            <div
              className="top-right"
              style={{
                position: "absolute",
                bottom: "140px",
                left: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "#2C5DFE",
              }}
            >
              <div>03.</div>
              <div style={{ padding: "0.3rem" }}>Choose Career</div>
            </div>
            <div
              className="bottom-right"
              style={{
                position: "absolute",
                bottom: "165px",
                left: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "#2C5DFE",
              }}
            >
              <div>02.</div>
              <div style={{ padding: "0.3rem" }}>Analyze Result</div>
            </div>

            <Typography
              className="bottom-right"
              sx={{
                position: "absolute",
                // top: "140px",
                // left: "10px",
                left:
                  window >= 1020
                    ? "10px"
                    : window.innerWidth >= 768
                    ? "10px"
                    : window.innerWidth >= 420
                    ? "10px"
                    : "10px",

                top:
                  window >= 1020
                    ? "140px"
                    : window.innerWidth >= 768
                    ? "120px"
                    : window.innerWidth >= 420
                    ? "120px"
                    : "90px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.6rem",
                fontWeight: "bold",
                fontSize: window.innerWidth > 480 ? "1.4rem" : "1rem",
                color: "#000000",
                fontFamily: "Margarine",
              }}
            >
              How do i give test?
            </Typography>
          </div>
        </div>
      </Box>
    </>
  );
}

export default RiasecAndLsmtPoster;
